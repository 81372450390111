import React, { useState, useEffect, useCallback, useRef } from 'react';
import { motion } from 'framer-motion';
import * as waxjs from "@waxio/waxjs/dist";
import AnchorLink from 'anchor-link';
import AnchorLinkBrowserTransport from 'anchor-link-browser-transport';
import { Buffer } from 'buffer';
import './MonkeyGame.css';
// Polyfill Buffer
window.Buffer = window.Buffer || Buffer;

const RareruggapesGame = () => {
  // Game constants
  const MONKEY_WIDTH = 180;
  const MONKEY_HEIGHT = 160;
  const BANANA_SIZE = 40;
  const ENEMY_SIZE = 60;
  const GAME_WIDTH = window.innerWidth;
  const GAME_HEIGHT = window.innerHeight;
  const MONKEY_MOVE_SPEED = 5;
  const GRAVITY = 0.5;
  const INITIAL_JUMP_VELOCITY = -15;
  const GROUND_LEVEL = GAME_HEIGHT - MONKEY_HEIGHT;
  const MAX_JUMP_VELOCITY = -25;
  const BASE_BANANA_SPEED = 3;
  const BASE_ENEMY_SPEED = 4;
  const MAX_SPEED_INCREASE = 5;
  const HEART_SIZE = 40;
  const INVULNERABILITY_DURATION = 2000;
  const RUGG_TOKEN_SIZE = 50;
  const RUGG_TOKEN_SPAWN_CHANCE = 0.005;
  const BOSS_WIDTH = 400;
  const BOSS_HEIGHT = 500;
  const PINEAPPLE_SIZE = 50;
  const BOSS_MOVE_SPEED = 3;
  const PINEAPPLE_SPEED = 6;
  const THROWN_BANANA_SIZE = 30;
  const THROWN_BANANA_SPEED = 10;
  const BOSS_DAMAGE_PER_HIT = 10;
  const MAX_THROW_ANGLE = Math.PI / 3;
  const MAGNET_DURATION = 5000; // 5 secondi
  const MAGNET_ATTRACTION_SPEED = 8; // Velocità d'attrazione delle banane

  
const MAGNET_SIZE = 100;

// Stato per i magneti
const [magnetPowerUps, setMagnetPowerUps] = useState([]);

  // State variables
  const [monkeyFrame, setMonkeyFrame] = useState(0);
  const [monkeyY, setMonkeyY] = useState(GROUND_LEVEL);
  const [monkeyX, setMonkeyX] = useState(50);
  const [bananas, setBananas] = useState([]);
  const [enemies, setEnemies] = useState([]);
  const [ruggTokens, setRuggTokens] = useState([]);
  const [score, setScore] = useState(0);
  const [gameStarted, setGameStarted] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [keyPressed, setKeyPressed] = useState({});
  const [nftCount, setNftCount] = useState(0);
  const [isCountingNFTs, setIsCountingNFTs] = useState(false);
  const [lives, setLives] = useState(3);
  const [isInvulnerable, setIsInvulnerable] = useState(false);
  const [isJumping, setIsJumping] = useState(false);
  const [jumpVelocity, setJumpVelocity] = useState(0);
  const [jumpCount, setJumpCount] = useState(0);
  const [userAccount, setUserAccount] = useState(null);
  const [wax, setWax] = useState(null);
  const [anchorLink, setAnchorLink] = useState(null);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [bananaCount, setBananaCount] = useState(0);
  const [bossActive, setBossActive] = useState(false);
  const [bossPosition, setBossPosition] = useState({ x: GAME_WIDTH, y: GROUND_LEVEL - BOSS_HEIGHT });
  const [pineapples, setPineapples] = useState([]);
  const [bossHealth, setBossHealth] = useState(100);
  const [thrownBananas, setThrownBananas] = useState([]);
  const [hearts, setHearts] = useState([]);
  const [magnetItems, setMagnetItems] = useState([]);  // Nuovi magneti nel gioco
  const [magnetActive, setMagnetActive] = useState(false);  // Stato del magnete attivo
  // Refs
  const audioRef = useRef(null);
  const jumpSoundRef = useRef(null);
  const collectSoundRef = useRef(null);
  const gameOverSoundRef = useRef(null);
  const heartSoundRef = useRef(null);
  const isColliding = useRef(false);
  const lastCollisionTime = useRef(0);
  const [bossHit, setBossHit] = useState(false);
  const [showBossWarning, setShowBossWarning] = useState(false);
  const [showLevelComplete, setShowLevelComplete] = useState(false);
const [platforms, setPlatforms] = useState([]);

  // Funzioni di supporto
  const getScrollSpeed = (baseSpeed) => {
    const speedIncrease = Math.min(score / 10, MAX_SPEED_INCREASE);
    return baseSpeed + speedIncrease;
  };

  const getMonkeyFrame = () => {
    const frameNumber = monkeyFrame % 3;
    return `${process.env.PUBLIC_URL}/images/run${frameNumber + 1}.png`;
  };

  const getBossImage = () => `${process.env.PUBLIC_URL}/images/boss.png`;
  const getPineappleImage = () => `${process.env.PUBLIC_URL}/images/enemy.png`;
  const generateHeart = useCallback(() => {
    if (Math.random() < 0.0005) {
      setHearts(prev => [...prev, {
        x: GAME_WIDTH,
        y: Math.random() * (GAME_HEIGHT - HEART_SIZE),
      }]);
    }
  }, []);

  const getMonkeyCollisionRect = useCallback(() => ({
    left: monkeyX,
    right: monkeyX + MONKEY_WIDTH,
    top: monkeyY,
    bottom: monkeyY + MONKEY_HEIGHT
  }), [monkeyX, monkeyY]);

  const playSound = (soundRef) => {
    if (soundRef.current) {
      soundRef.current.currentTime = 0;
      soundRef.current.play();
    }
  };

  // Attiva il magnete
  const activateMagnet = () => {
    setMagnetActive(true);
    setTimeout(() => setMagnetActive(false), MAGNET_DURATION); // Disattiva dopo MAGNET_DURATION ms
  };

  const throwBanana = useCallback(() => {
    if (bossActive) {
      setThrownBananas(prev => [...prev, {
        x: monkeyX + MONKEY_WIDTH,
        y: monkeyY + MONKEY_HEIGHT / 2,
      }]);
    }
  }, [bossActive, monkeyX, monkeyY]);
  
  

  // Game logic
  const updateGameState = useCallback(() => {
    if (keyPressed.ArrowLeft || keyPressed.KeyA) {
      setMonkeyX((prev) => Math.max(prev - MONKEY_MOVE_SPEED, 0));
    }
    if (keyPressed.ArrowRight || keyPressed.KeyD) {
      setMonkeyX((prev) => Math.min(prev + MONKEY_MOVE_SPEED, GAME_WIDTH - MONKEY_WIDTH));
    }

    if (isJumping) {
      setMonkeyY((prev) => {
        const newY = Math.max(prev + jumpVelocity, 0);
        if (newY >= GROUND_LEVEL) {
          setIsJumping(false);
          setJumpCount(0);
          return GROUND_LEVEL;
        }
        return newY;
      });
      setJumpVelocity((prev) => Math.min(prev + GRAVITY, 10));
    } else {
      setMonkeyY(GROUND_LEVEL);
    }

    setHearts(prev => prev
      .map(heart => ({ ...heart, x: heart.x - bananaSpeed }))
      .filter(heart => heart.x > -HEART_SIZE)
    );
    generateHeart();

    const bananaSpeed = getScrollSpeed(BASE_BANANA_SPEED);
    const enemySpeed = getScrollSpeed(BASE_ENEMY_SPEED);
    const ruggTokenSpeed = getScrollSpeed(BASE_BANANA_SPEED);

    // Modifica la parte delle banane in updateGameState per l'attrazione
setBananas(prev => {
  const newBananas = prev.map(banana => {
    if (magnetActive) {
      // Calcola la distanza tra la scimmia e la banana
      const deltaX = monkeyX - banana.x;
      const deltaY = monkeyY - banana.y;
      const distance = Math.sqrt(deltaX ** 2 + deltaY ** 2);
      
      // Se la distanza è entro un certo range, avvicina la banana
      if (distance < 500) { // Raggio d'attrazione del magnete
        const attractionSpeedX = (deltaX / distance) * MAGNET_ATTRACTION_SPEED;
        const attractionSpeedY = (deltaY / distance) * MAGNET_ATTRACTION_SPEED;
        
        return {
          ...banana,
          x: banana.x + attractionSpeedX, // Sposta la banana verso la scimmia
          y: banana.y + attractionSpeedY,
        };
      }
    }
    // Comportamento normale della banana
    return { ...banana, x: banana.x - bananaSpeed };
  }).filter(banana => banana.x > -BANANA_SIZE); // Rimuovi le banane fuori dallo schermo

  // Generazione casuale di nuove banane
  if (Math.random() < 0.02 && !bossActive) {
    newBananas.push({
      x: GAME_WIDTH,
      y: Math.random() * (GAME_HEIGHT - BANANA_SIZE - MONKEY_HEIGHT) + MONKEY_HEIGHT,
    });
  }

  return newBananas;
});


    setEnemies(prev => {
      const newEnemies = prev
        .map(enemy => ({ ...enemy, x: enemy.x - enemySpeed }))
        .filter(enemy => enemy.x > -ENEMY_SIZE);
      
      if (Math.random() < 0.01 && !bossActive) {
        newEnemies.push({
          x: GAME_WIDTH,
          y: GROUND_LEVEL + MONKEY_HEIGHT - ENEMY_SIZE,
        });
      }
      return newEnemies;
    });

    setRuggTokens(prev => {
      const newRuggTokens = prev
        .map(token => ({ ...token, x: token.x - ruggTokenSpeed }))
        .filter(token => token.x > -RUGG_TOKEN_SIZE);
      if (Math.random() < RUGG_TOKEN_SPAWN_CHANCE && !bossActive) {
        newRuggTokens.push({
          x: GAME_WIDTH,
          y: Math.random() * (GAME_HEIGHT - RUGG_TOKEN_SIZE - MONKEY_HEIGHT) + MONKEY_HEIGHT,
        });
      }
      return newRuggTokens;
    });

     // Genera nuovi magneti
     setMagnetItems(prev => prev
      .map(magnet => ({ ...magnet, x: magnet.x - bananaSpeed }))
      .filter(magnet => magnet.x > -HEART_SIZE)
    );

     // Genera il magnete (1% di probabilità)
     if (Math.random() < 0.0001) {
      setMagnetItems(prev => [...prev, { x: GAME_WIDTH, y: Math.random() * (GAME_HEIGHT - HEART_SIZE) }]);
    }
  
    setMagnetPowerUps(prev => {
      const newMagnets = prev
        .map(magnet => ({ ...magnet, x: magnet.x - bananaSpeed })) // Spostiamo il magnete come le banane
        .filter(magnet => magnet.x > -MAGNET_SIZE); // Rimuovi i magneti fuori dallo schermo
    
      if (Math.random() < 0.001 && !bossActive) { // Genera un nuovo magnete con probabilità bassa
        newMagnets.push({
          x: GAME_WIDTH,
          y: Math.random() * (GAME_HEIGHT - MAGNET_SIZE - MONKEY_HEIGHT) + MONKEY_HEIGHT,
        });
      }
    
      return newMagnets;
    });
    

    if (bossActive) {
      // Move boss
      setBossPosition(prev => ({
        x: prev.x > GAME_WIDTH / 2 ? prev.x - BOSS_MOVE_SPEED : GAME_WIDTH / 2,
        y: prev.y
      }));

      // Throw pineapples
      if (Math.random() < 0.02) {
        const angle = (Math.random() * MAX_THROW_ANGLE) - (MAX_THROW_ANGLE / 2);
        const speed = PINEAPPLE_SPEED;
        setPineapples(prev => [...prev, {
          x: bossPosition.x,
          y: bossPosition.y + BOSS_HEIGHT / 2,
          velocityX: -speed * Math.cos(angle),
          velocityY: speed * Math.sin(angle)
        }]);
      }

      // Move pineapples with trajectories
      setPineapples(prev => prev
        .map(pineapple => ({
          ...pineapple,
          x: pineapple.x + pineapple.velocityX,
          y: pineapple.y + pineapple.velocityY,
          velocityY: pineapple.velocityY + 0 // Add gravity effect
        }))
        .filter(pineapple => 
          pineapple.x > -PINEAPPLE_SIZE && 
          pineapple.x < GAME_WIDTH &&
          pineapple.y < GAME_HEIGHT
        )
      );
    }

      // Move thrown bananas
    setThrownBananas(prev => prev
      .map(banana => ({ ...banana, x: banana.x + THROWN_BANANA_SPEED }))
      .filter(banana => banana.x < GAME_WIDTH)
    );

  }, [keyPressed, isJumping, jumpVelocity, score, bossActive, monkeyX, monkeyY, magnetActive]);


  const checkCollisions = useCallback(() => {
    const monkeyRect = getMonkeyCollisionRect();

    setBananas(prev => prev.filter(banana => {
      const bananaRect = {
        left: banana.x,
        right: banana.x + BANANA_SIZE,
        top: banana.y,
        bottom: banana.y + BANANA_SIZE
      };

      if (
        monkeyRect.left < bananaRect.right &&
        monkeyRect.right > bananaRect.left &&
        monkeyRect.top < bananaRect.bottom &&
        monkeyRect.bottom > bananaRect.top
      ) {
        
        setBananaCount(prevCount => prevCount + 1);
        playSound(collectSoundRef);
        return false;
      }
      return true;
    }));

    setRuggTokens(prev => prev.filter(token => {
      const tokenRect = {
        left: token.x,
        right: token.x + RUGG_TOKEN_SIZE,
        top: token.y,
        bottom: token.y + RUGG_TOKEN_SIZE
      };

      if (
        monkeyRect.left < tokenRect.right &&
        monkeyRect.right > tokenRect.left &&
        monkeyRect.top < tokenRect.bottom &&
        monkeyRect.bottom > tokenRect.top
      ) {
        setScore(prevScore => prevScore + 5);
        playSound(collectSoundRef);
        return false;
      }
      return true;
    }));

    setHearts(prev => prev.filter(heart => {
      const heartRect = {
        left: heart.x,
        right: heart.x + HEART_SIZE,
        top: heart.y,
        bottom: heart.y + HEART_SIZE
      };
    
      if (
        monkeyRect.left < heartRect.right &&
        monkeyRect.right > heartRect.left &&
        monkeyRect.top < heartRect.bottom &&
        monkeyRect.bottom > heartRect.top
      ) {
        setLives(prevLives => Math.min(prevLives + 1, 10));
        playSound(heartSoundRef);
        return false;
      }
      return true;
    }));

    setMagnetItems(prev => prev.filter(magnet => {
      const magnetRect = {
        left: magnet.x,
        right: magnet.x + HEART_SIZE,
        top: magnet.y,
        bottom: magnet.y + HEART_SIZE
      };

      if (
        monkeyRect.left < magnetRect.right &&
        monkeyRect.right > magnetRect.left &&
        monkeyRect.top < magnetRect.bottom &&
        monkeyRect.bottom > magnetRect.top
      ) {
        activateMagnet();  // Attiva il potere del magnete
        return false;  // Rimuovi il magnete raccolto
      }
      return true;
    }));

    // Aggiungi nella funzione checkCollisions per attivare il magnete
setMagnetPowerUps(prev => prev.filter(magnet => {
  const magnetRect = {
    left: magnet.x,
    right: magnet.x + MAGNET_SIZE,
    top: magnet.y,
    bottom: magnet.y + MAGNET_SIZE
  };

  // Controlla se la scimmia raccoglie il magnete
  if (
    monkeyRect.left < magnetRect.right &&
    monkeyRect.right > magnetRect.left &&
    monkeyRect.top < magnetRect.bottom &&
    monkeyRect.bottom > magnetRect.top
  ) {
    activateMagnet();  // Attiva l'effetto calamita
    playSound(collectSoundRef);
    return false; // Rimuovi il magnete dalla schermata
  }
  return true;
}));


    if (!isInvulnerable) {
      const currentTime = Date.now();
      let hasCollided = false; // Aggiungi questa riga
      
      setEnemies(prev => prev.filter(enemy => {
        if (hasCollided) return true; // Aggiungi questa riga
    
        const enemyRect = {
          left: enemy.x + ENEMY_SIZE * 0.1,
          right: enemy.x + ENEMY_SIZE * 0.9,
          top: enemy.y + ENEMY_SIZE * 0.1,
          bottom: enemy.y + ENEMY_SIZE
        };
      
        if (
          monkeyRect.left < enemyRect.right &&
          monkeyRect.right > enemyRect.left &&
          monkeyRect.top < enemyRect.bottom &&
          monkeyRect.bottom > enemyRect.top
        ) {
          hasCollided = true; // Aggiungi questa riga
          setLives(prevLives => {
            const newLives = prevLives - 1;
            if (newLives <= 0) {
              setGameOver(true);
              stopGame();
            }
            return newLives;
          });
          
          setIsInvulnerable(true);
          setTimeout(() => setIsInvulnerable(false), INVULNERABILITY_DURATION);
          
          playSound(gameOverSoundRef);
          return false;
        }
        return true;
      }));
      

      // Aggiungiamo il controllo delle collisioni con gli ananas
      setPineapples(prev => prev.filter(pineapple => {
        const pineappleRect = {
          left: pineapple.x + PINEAPPLE_SIZE * 0.1,
          right: pineapple.x + PINEAPPLE_SIZE * 0.9,
          top: pineapple.y + PINEAPPLE_SIZE * 0.1,
          bottom: pineapple.y + PINEAPPLE_SIZE
        };
      
        if (
          monkeyRect.left < pineappleRect.right &&
          monkeyRect.right > pineappleRect.left &&
          monkeyRect.top < pineappleRect.bottom &&
          monkeyRect.bottom > pineappleRect.top
        ) {
          setLives(prevLives => {
            const newLives = prevLives - 1;
            console.log("New lives count:", newLives);
      
            if (newLives <= 0) {
              setGameOver(true);
              stopGame();
            } else {
              setIsInvulnerable(true);
              setTimeout(() => setIsInvulnerable(false), INVULNERABILITY_DURATION);
            }
            return newLives;
          });
          playSound(gameOverSoundRef);  // Suona il suono di danno
          return false;  // Rimuovi l'ananas colpito
        }
        return true;
      }));
      
      
      

      if (bossActive) {
        setThrownBananas(prev => prev.filter(banana => {
          const bananaRect = {
            left: banana.x,
            right: banana.x + THROWN_BANANA_SIZE,
            top: banana.y,
            bottom: banana.y + THROWN_BANANA_SIZE
          };
        
          const bossRect = {
            left: bossPosition.x,
            right: bossPosition.x + BOSS_WIDTH,
            top: bossPosition.y,
            bottom: bossPosition.y + BOSS_HEIGHT
          };
        
          if (
            bananaRect.left < bossRect.right &&
            bananaRect.right > bossRect.left &&
            bananaRect.top < bossRect.bottom &&
            bananaRect.bottom > bossRect.top
          ) {
            setBossHealth(prevHealth => {
              const newHealth = prevHealth - BOSS_DAMAGE_PER_HIT;
              // Inserisci qui il nuovo codice
              if (newHealth <= 0) {
                setBossActive(false);
                setScore(prevScore => prevScore + 100);
                setShowLevelComplete(true);
                setTimeout(() => {
                  setShowLevelComplete(false);
                  setGameOver(true);
                  stopGame();
                }, 3000);
              }
              return Math.max(newHealth, 0);
            });
            setBossHit(true);
            setTimeout(() => setBossHit(false), 200);
            return false;
          }
          return true;
        }));
      }
    }
}, [getMonkeyCollisionRect, isInvulnerable, bossActive, bossPosition]); // Corretto: la chiusura del callback è stata aggiunta qui




  // Game initialization
  useEffect(() => {
    const initWax = async () => {
      const waxInstance = new waxjs.WaxJS({
        rpcEndpoint: 'https://wax.greymass.com',
        tryAutoLogin: false
      });
      setWax(waxInstance);

      const transport = new AnchorLinkBrowserTransport();
      const link = new AnchorLink({
        transport,
        chains: [{
          chainId: '1064487b3cd1e6239b3cdcdf7e6e4f7765dbbdf4be671c5765f3b2feacf49fc2',
          nodeUrl: 'https://wax.greymass.com',
        }],
      });
      setAnchorLink(link);
    };
    initWax();

    jumpSoundRef.current = new Audio(`${process.env.PUBLIC_URL}/jump.mp3`);
    collectSoundRef.current = new Audio(`${process.env.PUBLIC_URL}/collect.mp3`);
    gameOverSoundRef.current = new Audio(`${process.env.PUBLIC_URL}/game_over.mp3`);
    heartSoundRef.current = new Audio(`${process.env.PUBLIC_URL}/heart.mp3`);

    jumpSoundRef.current.load();
    collectSoundRef.current.load();
    gameOverSoundRef.current.load();
    heartSoundRef.current.load()
  }, []);

  // Key event handlers
  useEffect(() => {
    const handleKeyDown = (e) => {
      setKeyPressed(prev => ({ ...prev, [e.code]: true }));
      if ((e.code === 'Space' || e.code === 'KeyW') && jumpCount < 2) {
        setIsJumping(true);
        setJumpVelocity(INITIAL_JUMP_VELOCITY);
        setJumpCount(prevJumpCount => prevJumpCount + 1);
        playSound(jumpSoundRef);
      }
      if (e.code === 'KeyF') {
        throwBanana();
      }
    };

    const handleKeyUp = (e) => {
      setKeyPressed(prev => ({ ...prev, [e.code]: false }));
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [jumpCount, throwBanana]);

  // Game loop
  useEffect(() => {
    if (!gameStarted || gameOver) return;

    let lastFrameChangeTime = Date.now();
    const frameDuration = 300;

    const gameLoop = setInterval(() => {
      updateGameState();
      checkCollisions();

      const currentTime = Date.now();
      if (currentTime - lastFrameChangeTime >= frameDuration) {
        setMonkeyFrame((prev) => (prev + 1) % 3);
        lastFrameChangeTime = currentTime;
      }
    }, 20);

    return () => clearInterval(gameLoop);
  }, [gameStarted, gameOver, updateGameState, checkCollisions]);

  // Boss battle trigger
  useEffect(() => {
    if (bananaCount >= 100 && !bossActive) {
      setShowBossWarning(true);
      setTimeout(() => {
        setShowBossWarning(false);
        setBossActive(true);
        setBananaCount(0);
      }, 3000);
    }
  }, [bananaCount, bossActive]);

const [userNFTs, setUserNFTs] = useState([]);
  const [selectedNFT, setSelectedNFT] = useState(null);
  const [isLoadingNFTs, setIsLoadingNFTs] = useState(false);

  // Modified function to fetch user's NFTs
  const fetchUserNFTs = async (accountName) => {
    if (!wax) {
      console.error('WAX instance not initialized');
      return [];
    }
  
    try {
      const result = await wax.rpc.get_table_rows({
        code: 'atomicassets',
        scope: accountName,
        key_type: "name",
        table: 'assets',
        limit: 1000,
        index_position: 1,
        json: true,
      });
  
      if (!result || !result.rows) {
        console.error('Invalid response from query');
        return [];
      }
  
      // Filtra per mostrare solo gli NFT della collezione "rareruggapes"
      const rareruggapesNFTs = result.rows.filter(row => row.collection_name === 'rareruggapes');
  
      // Fetch metadata per ogni NFT della collezione
      const nftsWithMetadata = await Promise.all(rareruggapesNFTs.map(async (nft) => {
        try {
          // Recupera il template data
          const templateResponse = await fetch(`https://wax.api.atomicassets.io/atomicassets/v1/templates/${nft.collection_name}/${nft.template_id}`);
          const templateData = await templateResponse.json();
  
          // Estrarre immagine e nome da immutable_data
          const imgCID = templateData.data.immutable_data.img;  // CID dell'immagine da IPFS
          const name = templateData.data.immutable_data.name;  // Nome dell'NFT
  
          return {
            ...nft,
            metadata: templateData.data,
            image: `https://ipfs.io/ipfs/${imgCID}`,  // Costruzione dell'URL IPFS
            name: name
          };
        } catch (error) {
          console.error('Error fetching template data:', error);
          return nft;
        }
      }));
  
      return nftsWithMetadata;
    } catch (error) {
      console.error('Error fetching NFTs:', error);
      return [];
    }
  };
  

  

  // Modified login functions
  const loginWithWCW = async () => {
    if (!wax) {
      console.error('WAX instance not initialized');
      alert('WAX instance not initialized. Please reload the page and try again.');
      return;
    }

    setIsLoggingIn(true);
    try {
      const userAccount = await wax.login();
      setUserAccount(userAccount);
      
      setIsLoadingNFTs(true);
      const nfts = await fetchUserNFTs(userAccount);
      setUserNFTs(nfts);
      setNftCount(nfts.length);
    } catch (error) {
      console.error('WAX Cloud Wallet login failed:', error);
      alert(`Login failed: ${error.message || 'Unknown error'}`);
    } finally {
      setIsLoggingIn(false);
      setIsLoadingNFTs(false);
    }
  };

  const loginWithAnchor = async () => {
    if (!anchorLink) {
      console.error('Anchor Link not initialized');
      alert('Anchor Link not initialized. Please reload the page and try again.');
      return;
    }
  
    setIsLoggingIn(true);
    try {
      const identity = await anchorLink.login('MonkeyGame');
      setUserAccount(identity.account.name);
      
      setIsLoadingNFTs(true);
      const nfts = await fetchUserNFTs(identity.account.name);
      setUserNFTs(nfts);
      setNftCount(nfts.length);
    } catch (error) {
      console.error('Anchor login failed:', error);
      alert(`Login failed: ${error.message || 'Unknown error'}`);
    } finally {
      setIsLoggingIn(false);
      setIsLoadingNFTs(false);
    }
  };

  // Add NFT selection screen component
  const NFTSelectionScreen = () => (
    <div className="nft-selection-screen" style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      color: 'white',
      minHeight: '100vh',  // Cambiato da 'height' a 'minHeight' per permettere l'espansione
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      overflowY: 'auto',  // Aggiunto overflow per abilitare lo scroll
    }}>
      <h2 style={{ marginBottom: '20px' }}>Select your NFT to play with</h2>
  
      {isLoadingNFTs ? (
        <div>Loading your NFTs...</div>
      ) : userNFTs.length === 0 ? (
        <div>
          <p>You don't have any Rareruggapes NFTs.</p>
          <button onClick={() => setSelectedNFT('default')}>
            Play with default character
          </button>
        </div>
      ) : (
        <div style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
          gap: '20px',
          width: '100%',
          maxWidth: '1200px',
          padding: '20px',
        }}>
          {userNFTs.map((nft) => (
            <div
              key={nft.asset_id}
              onClick={() => setSelectedNFT(nft)}
              style={{
                border: selectedNFT?.asset_id === nft.asset_id ? '3px solid gold' : '1px solid white',
                borderRadius: '10px',
                padding: '10px',
                cursor: 'pointer',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                transition: 'transform 0.2s',
                ':hover': {
                  transform: 'scale(1.05)'
                }
              }}
            >
              <img
                src={nft.image}
                alt={nft.name}
                style={{
                  width: '100%',
                  height: 'auto',
                  borderRadius: '5px'
                }}
              />
              <p style={{ textAlign: 'center', marginTop: '10px' }}>{nft.name}</p>
            </div>
          ))}
        </div>
      )}
  
      {selectedNFT && (
        <button
          onClick={startGame}
          style={{
            marginTop: '20px',
            padding: '10px 20px',
            fontSize: '18px',
            backgroundColor: '#4CAF50',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer'
          }}
        >
          Start Game
        </button>
      )}
    </div>
  );
  

  // Game control functions
  const startGame = () => {
    setGameStarted(true);
    setGameOver(false);
    setScore(0);
    setLives(3);
    setBananas([]);
    setEnemies([]);
    setMonkeyY(GROUND_LEVEL);
    setMonkeyX(50);
    setBananaCount(0);
    setBossActive(false);
    setBossPosition({ x: GAME_WIDTH, y: GROUND_LEVEL - BOSS_HEIGHT });
    setPineapples([]);
    setBossHealth(100);

    if (audioRef.current) {
      audioRef.current.currentTime = 0;
      audioRef.current.play();
    }
  };

  const stopGame = () => {
    setGameStarted(false);
    setGameOver(true);
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  };

  // Render function

  return (
    <div className="game-container" style={{ width: '100vw', height: '100vh', overflow: 'hidden', position: 'relative' }}>
      <audio ref={audioRef} src={`${process.env.PUBLIC_URL}/monkey_dance_base.mp3`} loop />
      <audio ref={jumpSoundRef} src={`${process.env.PUBLIC_URL}/jump.mp3`} />
      <audio ref={collectSoundRef} src={`${process.env.PUBLIC_URL}/collect.mp3`} />
      <audio ref={gameOverSoundRef} src={`${process.env.PUBLIC_URL}/game_over.mp3`} />
  
      {!userAccount ? (
        <div
          className="home-screen"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100vw',
            height: '100vh',
            backgroundColor: 'blue', // Sfondo rosso
            overflow: 'hidden', // Per evitare che gli elementi fuoriescano dallo schermo
          }}
        >
          <h1
            className="blinking-text"
            style={{
              fontSize: '150px',
              color: 'white',
              marginBottom: '40px',
              textAlign: 'center',
              fontFamily: "'Permanent Marker', cursive", // Font applicato
            }}
          >
            Rareruggapes
          </h1>
          <div className="login-buttons" style={{ display: 'flex', flexDirection: 'column', gap: '40px' }}>
            <button onClick={loginWithWCW} disabled={isLoggingIn}>
              {isLoggingIn ? 'Logging in...' : 'Login with WAX Cloud Wallet'}
            </button>
            <button onClick={loginWithAnchor} disabled={isLoggingIn}>
              {isLoggingIn ? 'Logging in...' : 'Login with Anchor Wallet'}
            </button>
          </div>
  
          {/* Aggiungiamo più immagini Mr. Banana con dimensioni ridotte */}
          <img src={`${process.env.PUBLIC_URL}/images/mr_banana.png`} alt="Mr. Banana" className="floating-banana" style={{ top: '10%', left: '10%' }} />
          <img src={`${process.env.PUBLIC_URL}/images/mr_banana.png`} alt="Mr. Banana" className="floating-banana" style={{ top: '10%', left: '80%' }} />
          <img src={`${process.env.PUBLIC_URL}/images/mr_banana.png`} alt="Mr. Banana" className="floating-banana" style={{ top: '80%', left: '10%' }} />
          <img src={`${process.env.PUBLIC_URL}/images/mr_banana.png`} alt="Mr. Banana" className="floating-banana" style={{ top: '75%', left: '85%' }} />
          
        </div>

) : !selectedNFT ? (
  // NFT Selection Screen
  <div className="nft-selection-screen" style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    color: 'white',
    height: '100vh',
    overflowY: 'auto',
    width: '100%',    // Abilita lo scrolling
    backgroundColor: 'blue'
  }}>
    <h2 style={{ 
      marginBottom: '20px', 
      fontSize: '40px',
      fontFamily: "'Permanent Marker', cursive"
    }}>
      Select your NFT to play with
    </h2>
    
    {isLoadingNFTs ? (
      <div style={{ fontSize: '24px' }}>Loading your NFTs...</div>
    ) : userNFTs.length === 0 ? (
      <div style={{ textAlign: 'center' }}>
        <p style={{ fontSize: '24px', marginBottom: '20px' }}>You don't have any Rareruggapes NFTs.</p>
        <button 
          onClick={() => setSelectedNFT('default')}
          style={{
            padding: '15px 30px',
            fontSize: '20px',
            backgroundColor: '#4CAF50',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            transition: 'background-color 0.3s'
          }}
        >
          Play with default character
        </button>
      </div>
    ) : (
      <div style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
        gap: '20px',
        width: '100%',
        maxWidth: '1200px',
        padding: '20px'
      }}>
        {userNFTs.map((nft) => (
          <div
            key={nft.asset_id}
            onClick={() => setSelectedNFT(nft)}
            style={{
              border: selectedNFT?.asset_id === nft.asset_id ? '3px solid gold' : '1px solid white',
              borderRadius: '10px',
              padding: '10px',
              cursor: 'pointer',
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              transition: 'all 0.2s',
              transform: selectedNFT?.asset_id === nft.asset_id ? 'scale(1.05)' : 'scale(1)',
              boxShadow: selectedNFT?.asset_id === nft.asset_id ? '0 0 20px gold' : 'none'
            }}
          >
            <img
              src={nft.image}
              alt={nft.name}
              style={{
                width: '100%',
                height: 'auto',
                borderRadius: '5px'
              }}
            />
            <p style={{ 
              textAlign: 'center', 
              marginTop: '10px',
              fontSize: '18px',
              color: selectedNFT?.asset_id === nft.asset_id ? 'gold' : 'white'
            }}>
              {nft.name}
            </p>
          </div>
        ))}
      </div>
    )}
    
    {selectedNFT && (
      <button
        onClick={startGame}
        style={{
          marginTop: '20px',
          padding: '15px 30px',
          fontSize: '24px',
          backgroundColor: '#4CAF50',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          transition: 'background-color 0.3s',
          fontFamily: "'Permanent Marker', cursive"
        }}
      >
        Start Game
      </button>
    )}
  </div>
      ) : (
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
          
        
          <div
            className="game-background"
            style={{
              position: 'center',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundImage: `url(${process.env.PUBLIC_URL}/images/forest.png)`,
             
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              zIndex: -1,
            }}
          />
            
          {!gameStarted && !gameOver && (
            <div className="start-screen centered">
              <h2>Welcome, {userAccount}</h2>
              <button onClick={startGame} className="start-button">Start Game</button>
            </div>
          )}
  
          {gameStarted && (
            <>
              <div
                className="lives-container"
                style={{
                  position: 'absolute',
                  top: '20px',
                  left: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <div style={{ color: 'white', fontSize: '20px', marginBottom: '5px' }}>
                  LIVES:
                </div>
                <div style={{ display: 'flex', gap: '1px', marginTop: '2px' }}>
                  {[...Array(Math.max(lives, 0))].map((_, index) => (
                    <img
                      key={index}
                      src={`${process.env.PUBLIC_URL}/images/heart.png`}
                      alt="Heart"
                      style={{ width: `${HEART_SIZE}px`, height: `${HEART_SIZE}px` }}
                    />
                  ))}
                </div>
              </div>
  
              <motion.div
                className={`monkey ${isInvulnerable ? 'monkey-hit' : ''}`}
                style={{
                  position: 'absolute',
                  top: `${monkeyY}px`,
                  left: `${monkeyX}px`,
                  width: `${MONKEY_WIDTH}px`,
                  height: `${MONKEY_HEIGHT}px`,
                  // Aggiungi la luce gialla se il magnete è attivo
                  boxShadow: magnetActive ? '0px 0px 30px 15px yellow' : 'none', 
                  transition: 'box-shadow 0.3s ease'
                }}
                animate={{
                  border: isInvulnerable ? '4px solid red' : 'none',
                  opacity: isInvulnerable ? [1, 0.5, 1] : 1,
                }}
                transition={{
                  opacity: {
                    duration: 0.2,
                    repeat: Infinity,
                    ease: "linear"
                  }
                }}
              >
                <img 
                  src={getMonkeyFrame()} 
                  alt="Monkey running" 
                  style={{ width: '100%', height: '100%' }} 
                />
              </motion.div>

  
              {bananas.map((banana, index) => (
                <motion.div
                  key={index}
                  className="banana"
                  style={{
                    position: 'absolute',
                    top: `${banana.y}px`,
                    left: `${banana.x}px`,
                    width: `${BANANA_SIZE}px`,
                    height: `${BANANA_SIZE}px`,
                    backgroundImage: `url(${process.env.PUBLIC_URL}/images/banana.png)`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                  }}
                />
              ))}
  
              {enemies.map((enemy, index) => (
                <motion.div
                  key={index}
                  className="enemy"
                  style={{
                    position: 'absolute',
                    top: `${enemy.y}px`,
                    left: `${enemy.x}px`,
                    width: `${ENEMY_SIZE}px`,
                    height: `${ENEMY_SIZE}px`,
                    backgroundImage: `url(${process.env.PUBLIC_URL}/images/enemy.png)`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                  }}
                />
              ))}
  
              {ruggTokens.map((token, index) => (
                <motion.div
                  key={`rugg-${index}`}
                  className="rugg-token"
                  style={{
                    position: 'absolute',
                    top: `${token.y}px`,
                    left: `${token.x}px`,
                    width: `${RUGG_TOKEN_SIZE}px`,
                    height: `${RUGG_TOKEN_SIZE}px`,
                    backgroundImage: `url(${process.env.PUBLIC_URL}/images/rugg_logo.png)`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                  }}
                />
              ))}

                {hearts.map((heart, index) => (
                  <motion.div
                    key={`heart-${index}`}
                    className="heart"
                    style={{
                      position: 'absolute',
                      top: `${heart.y}px`,
                      left: `${heart.x}px`,
                      width: `${HEART_SIZE}px`,
                      height: `${HEART_SIZE}px`,
                      backgroundImage: `url(${process.env.PUBLIC_URL}/images/heart.png)`,
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                    }}
                  />
                ))}


                  {magnetItems.map((magnet, index) => (
                    <motion.div
                      key={`magnet-${index}`}
                      className="magnet"
                      style={{
                        position: 'absolute',
                        top: `${magnet.y}px`,
                        left: `${magnet.x}px`,
                        width: `${HEART_SIZE}px`,
                        height: `${HEART_SIZE}px`,
                        backgroundImage: `url(${process.env.PUBLIC_URL}/images/magnet.png)`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                      }}
                    />
                  ))}

            {magnetPowerUps.map((magnet, index) => (
              <motion.div
                key={`magnet-${index}`}
                className="magnet"
                style={{
                  position: 'absolute',
                  top: `${magnet.y}px`,
                  left: `${magnet.x}px`,
                  width: '40px',
                  height: '40px',
                  backgroundImage: `url(${process.env.PUBLIC_URL}/images/magnet.png)`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                }}
              />
            ))}

                  
              {bossActive && (
                <>
                  <motion.div
                    className="boss"
                    style={{
                      position: 'absolute',
                      top: `${bossPosition.y}px`,
                      left: `${bossPosition.x}px`,
                      width: `${BOSS_WIDTH}px`,
                      height: `${BOSS_HEIGHT}px`,
                      boxSizing: 'border-box'
                    }}
                    animate={{
                      border: bossHit ? '5px solid red' : 'none',
                      opacity: bossHit ? [1, 0.6, 1] : 1,
                      scale: bossHit ? [1, 1.05, 1] : 1,
                    }}
                    transition={{
                      opacity: {
                        duration: 0.2,
                        repeat: Infinity,
                        ease: "linear"
                      },
                      scale: {
                        duration: 0.2,
                        repeat: Infinity,
                        ease: "linear"
                      }
                    }}
                  >
                    <img 
                      src={getBossImage()} 
                      alt="Boss" 
                      style={{ width: '100%', height: '100%' }} 
                    />
                  </motion.div>
  
                  {pineapples.map((pineapple, index) => {
                    const angle = Math.atan2(pineapple.velocityY, pineapple.velocityX);
                    return (
                      <motion.div
                        key={`pineapple-${index}`}
                        className="pineapple"
                        style={{
                          position: 'absolute',
                          top: `${pineapple.y}px`,
                          left: `${pineapple.x}px`,
                          width: `${PINEAPPLE_SIZE}px`,
                          height: `${PINEAPPLE_SIZE}px`,
                          backgroundImage: `url(${getPineappleImage()})`,
                          backgroundSize: 'contain',
                          backgroundRepeat: 'no-repeat',
                          
                        }}
                      />
                    );
                  })} 
  
                  <div className="boss-health" style={{
                    position: 'absolute',
                    top: '20px',
                    right: '20px',
                    width: '200px',
                    height: '20px',
                    backgroundColor: 'gray',
                    border: '2px solid black',
                  }}>
                    <div style={{
                      width: `${bossHealth}%`,
                      height: '100%',
                      backgroundColor: 'red',
                    }} />
                  </div>
                </>
              )}

                {showBossWarning && (
                  <div className="boss-warning" style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    color: 'white',
                    padding: '20px',
                    borderRadius: '10px',
                    fontSize: '24px',
                    zIndex: 100,
                  }}>
                    OHMYGOD! Boss is coming...
                  </div>
                )}

                  {showLevelComplete && (
                    <div className="level-complete" style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      backgroundColor: 'rgba(0, 0, 0, 0.8)',
                      color: 'white',
                      padding: '20px',
                      borderRadius: '10px',
                      fontSize: '24px',
                      zIndex: 100,
                    }}>
                      Congrats! Level complete
                    </div>
                  )}

              {thrownBananas.map((banana, index) => (
                <motion.div
                  key={`thrown-banana-${index}`}
                  className="thrown-banana"
                  style={{
                    position: 'absolute',
                    top: `${banana.y}px`,
                    left: `${banana.x}px`,
                    width: `${THROWN_BANANA_SIZE}px`,
                    height: `${THROWN_BANANA_SIZE}px`,
                    backgroundImage: `url(${process.env.PUBLIC_URL}/images/banana.png)`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    transform: 'rotate(90deg)', // Rotate the banana to face the boss
                  }}
                />
              ))}
  
              <div className="score" style={{ position: 'absolute', top: '20px', left: '50%', transform: 'translateX(-50%)', color: 'white', fontSize: '24px' }}>
                <strong>$RUGG: {score}</strong>
              </div>
              {/* Nuovo pannello dei controlli */}
              <div className="controls-panel" style={{
                position: 'absolute',
                top: '20px',
                right: '20px',
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                padding: '15px',
                borderRadius: '10px',
                color: 'white',
                display: bossActive ? 'none' : 'block'  // Nascondi durante il boss fight
              }}>
                <h3 style={{ marginBottom: '10px', fontSize: '20px', borderBottom: '2px solid white' }}>Controls</h3>
                <ul style={{ listStyle: 'none', padding: 0 }}>
                  <li style={{ marginBottom: '8px' }}>
                    <span style={{ color: 'yellow' }}>← →</span> or <span style={{ color: 'yellow' }}>A D</span>: Move
                  </li>
                  <li style={{ marginBottom: '8px' }}>
                    <span style={{ color: 'yellow' }}>SPACE</span> or <span style={{ color: 'yellow' }}>W</span>: Jump
                  </li>
                  <li style={{ marginBottom: '8px' }}>
                    Double jump available!
                  </li>
                  <li style={{ marginBottom: '8px' }}>
                    <span style={{ color: 'yellow' }}>F</span>: Throw banana (during boss fight)
                  </li>
                </ul>
                <div style={{ marginTop: '10px', fontSize: '14px', borderTop: '1px solid white', paddingTop: '10px' }}>
                  Collect 100 bananas to fight the boss!
                </div>
              </div>
            </>
          )}

                {bossActive && (
                <div className="boss-controls" style={{
                  position: 'absolute',
                  top: '60px',
                  right: '20px',
                  backgroundColor: 'rgba(255, 0, 0, 0.7)',
                  padding: '15px',
                  borderRadius: '10px',
                  color: 'white'
                }}>
                  <h3 style={{ marginBottom: '10px', fontSize: '20px', borderBottom: '2px solid white' }}>Boss Fight!</h3>
                  <ul style={{ listStyle: 'none', padding: 0 }}>
                    <li style={{ marginBottom: '8px' }}>
                      <span style={{ color: 'yellow' }}>F</span>: Throw bananas
                    </li>
                    <li style={{ marginBottom: '8px' }}>
                      Dodge pineapples!
                    </li>
                  </ul>
                </div>
              )}

          <div className="wallet-container" style={{ position: 'absolute', bottom: '20px', left: '20px', color: 'white' }}>
            <div className="user-info">
              <h3 style={{ color: 'white', fontSize: '24px', marginBottom: '10px' }}>
                Your Rareruggapes NFTs: {nftCount}
              </h3>
            </div>
          </div>
  
          {gameOver && (
            <div className="game-over-screen centered" >
              <h1 className="game-over-text">Game Over</h1>
              <button onClick={startGame}>Play Again</button>
            </div>
          )}
        </div>
      )}
    </div>
  );
} 
export default RareruggapesGame;