import React from 'react';
import MonkeyGame from './components/MonkeyGame';

function App() {
  return (
    <div className="App">
      <MonkeyGame />
    </div>
  );
}

export default App;

